
export function ValidateSessionTicket() {

    if (localStorage.getItem("loginTime") !== null) {
        const loginDate = new Date(localStorage.getItem("loginTime"));
        const currentDate = new Date();

        const msBetweenDates = Math.abs(
            loginDate.getTime() - currentDate.getTime()
        );
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (hoursBetweenDates < 72) {
            console.log(
                "login was " + Math.round(hoursBetweenDates) + " hours ago"
            );
        } else {
            console.log("login was NOT within 72 hours");
            localStorage.clear();
        }
    } else {
        console.log("no login found");
    }
}
