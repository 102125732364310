import React from "react";

import immersify_logo from "../../images/immersify_logo.svg";
import shape_1 from "../../images/shape_1.svg";

import {useParams, useSearchParams} from "react-router-dom";

var id_token;

export const UniSSOApp = () => {
    id_token = Object.values(useParams())[0];
    const state = useSearchParams()[0].get("state");
    console.log("State variable: ", state);
    const isDesktop = state?.includes("desktop") || false;


    async function attemptDesktopRedirect() {
        // Todo: This needs to be replaced with a better solution
        // For now, the goal is to attempt to call the desktop app server
        // and if it fails, redirect to the mobile app

        const userAgent = navigator.userAgent
        const macosStrings = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "e"]

        const url = "http://localhost:8080/immersifyeducation://immersifydental?idToken=" + encodeURIComponent(id_token);

        if (macosStrings.some(
            (macosString) => userAgent.includes(macosString)
        )) {

            console.log("Url: ", url)
            console.log("MacOS, attempting redirect")
            window.location.href = url
            console.log("MacOS, href redirect attempted")
            return false; // This is a guess, we don't know if the redirect worked
        }

        console.log("Not MacOS, attempting fetch")

        return await fetch((url)).then(
            (response) => {
                console.log("Not macos, fetch succeeded, response: ", response)
                return response.ok;
            }
        ).catch(
            (error) => {
                console.log("Not macos, fetch failed, error: ", error)
                return false;
            }
        )

    }

    async function handleManualRedirect() {
        if (isDesktop) {
            attemptDesktopRedirect();
        } else {
            window.location.href =
                "immersifyeducation://immersifydental?idToken=" + id_token;
        }
    }

    handleManualRedirect();

    return (
        <>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 col-md-6 left_block d-none d-md-block">
                        <div className="signup_image">
                            <div className="logo_wrap">
                                <img alt="placeholder" src={immersify_logo}/>
                            </div>
                            <div className="shape_block">
                                <div className="shape_1">
                                    <img alt="placeholder" src={shape_1}/>
                                </div>
                                <div className="shape_2">
                                    <img alt="placeholder" src={shape_1}/>
                                </div>
                            </div>
                            <div className="login_text_block">
                                <h1>LEARN.</h1>
                                <h1>EXPERIENCE.</h1>
                                <h1>CONNECT.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                        {isDesktop ? (
                            <div className={"d-flex h-100"}>
                                <button className={"btn_common m-auto"} onClick={attemptDesktopRedirect}>Click here to complete login.</button>
                            </div>

                        ) : (
                            <div>
                                <div className="signup_form_area">
                                    <h3>Please do not refresh </h3>
                                    <h2>Redirecting...</h2>
                                </div>
                                <button onClick={handleManualRedirect}>
                                    If you have been waiting longer than a few seconds
                                    please click here
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
        ;
};
